@import '@angular/cdk/overlay-prebuilt.css';


@icon-size: 20px;
@menu-bar-height: 50px;
@menu-bar-width: 40px;
:root {
  --mtl-blue: #00A9B0;
  --mtl-highlight-blue: #eefdfe;
  --mtl-blue-rgb: 0, 169, 176;
  --acm-highlight-bg-color-rgba: var(--mtl-dark-blue-rgb), 0.1;
  --mtl-dark-blue: #005558;
  --mtl-dark-blue-rgb: 0, 85, 88;
  --mtl-dark-background: #1a1e23;
  --mtl-dark-background-rgb: 26, 30, 35;
  --mtl-timeline-rgb: 136, 136, 136;
  --mtl-bright-background: #fdfdfd;
  --mtl-bright-background-rgb: 253, 253, 253;
  --mtl-bright-background-alternating-row: #eaeaea;
  --mtl-aggregation-residence-rgb: var(--mtl-dark-background-rgb);
  --mtl-aggregation-emigration-rgb: 83, 168, 0;
  --mtl-aggregation-expulsion-rgb: 255, 89, 0;
  --mtl-aggregation-imprisonment-rgb: 222, 55, 0;
  --mtl-aggregation-deportation-rgb: 173, 7, 7;
  --mtl-aggregation-birth-rgb: 150, 150, 150;
  --mtl-aggregation-death-rgb: 150, 150, 150;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  --placeHolder-color: #3b3b3b;
  --placeHolder-opacity: 0.5;
}

input::placeholder {
  color: var(--placeHolder-color);
  opacity: var(--placeHolder-color) !important;
}

input::-webkit-input-placeholder { /* Edge */
  color: var(--placeHolder-color);
  opacity: var(--placeHolder-color) !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--placeHolder-color);
  opacity: var(--placeHolder-color) !important;
}

input:-moz-placeholder {
  color: var(--placeHolder-color);
  opacity: var(--placeHolder-color) !important;
}

input::-moz-placeholder {
  color: var(--placeHolder-color);
  opacity: var(--placeHolder-opacity) !important;
}

html {
  background: rgba(var(--mtl-dark-background-rgb), 1);
  height: 100vh !important;
  width: 100vw !important;

  @media (max-height: 900px) and (orientation:portrait) {
    @supports (-webkit-touch-callout: none) {
     height: calc(100dvh + 100vh - 100dvh + 10px) !important;
      .footer {
        // margin-top: 100px;
        padding-top: 15px!important;
      }
    }
    app-root {
      flex-direction: column-reverse;
    }
  }
}
body{
  // background: rgba(var(--mtl-dark-background-rgb), 1);
  height: 100%;
  width: 100%;
  margin: 0px !important;
  padding: 0;
  font-family: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 20px;
  
  * {
    scrollbar-color: #9BBBBC transparent;
    scrollbar-width: thin;
  }
  *::-webkit-scrollbar-thumb {
    background: #9BBBBC;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mtl-scrollbar {
  &::-webkit-scrollbar-thumb {
    background: #9BBBBC;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}

.mtl-scroll-shadows-parent {
  overflow: auto;
  &::before {
    content: '';
    background:     /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center top;
      background: radial-gradient(
        farthest-side at 50% 0, rgba(0, 0, 0, 0.55) 1%, rgba(90, 90, 90, 0.48) 20%, rgba(255, 255, 255, 0.35) 39%, rgba(255, 255, 255, 0.15) 64%, rgba(0, 150, 136, 0) 84%, rgba(143, 143, 143, 0) 100%) center top;
    background: linear-gradient(0deg, rgba(0, 188, 212, 0) 0%, rgba(0, 0, 0, 0.34) 95%, rgba(143, 143, 143, 0) 100%) center top;
    height: 12px;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 98;
    width: 100%;
  }
  
  &::after {
    content: '';
    background:       /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center bottom;
    background: linear-gradient(180deg, rgba(0, 188, 212, 0) 0%, rgba(0, 0, 0, 0.34) 95%, rgba(143, 143, 143, 0) 100%) center bottom;
    height: 12px;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 98;
    margin-top: -10px;
    width: 100%;
  }
}

.mtl-scroll-shadows {
  position: relative;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  &::before {
    background:
    /* Shadow Cover TOP */
    linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
      ) center top;
    // background: white;
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 3px;
    height: 16px;
    z-index: 99;
    // width: 100%;
  }

  &::after {
    background:
    /* Shadow Cover BOTTOM */
    linear-gradient(
      rgba(0, 0, 0, 0), 
      white 30%
      ) center bottom;
    // background: white;
    content: '';
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 10px;
    height: 16px;
    // width: 100%;
    z-index: 99;
  }
}

.mtl-horizontal-scroll-fade-overlay {
  mask-image: linear-gradient(180deg, transparent 0%, #000000 15px, #000000 calc(100% - 15px), transparent 100%);
  height: 100%;
  overflow-y: auto;
}

.mtl-map-overlay-wrapper {
  pointer-events: all;
  background-color: rgba(var(--mtl-dark-background-rgb), 1);
  backdrop-filter: blur(8px);
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(var(--mtl-dark-background-rgb), 0.5);
}

.pac-container {
  max-height: 120px;
  overflow-y: auto ;
}

.outer-wrapper {
  height: 100dvh;
  width: 100%;
  flex-grow: 1;
  margin: 0px;
  position: relative;
  overflow: hidden;
}

.fill-available-height {
  min-height: 0;
  height: 100%;
}

.hidden {
  display: none!important;
}

.inverted {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

a {
  color: #00A9B0;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

h1,h2,h3,h4 {
  scroll-margin-top: 20px;
}


.loading-spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  display: inline-block;
  align-self: center;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #9BBBBC;
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #9BBBBC transparent transparent transparent;
}
.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media( max-width:800px ) {
  div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 135px!important;
  }
}

.button_ {
  min-width: 250px;
  padding: 15px 20px;
  margin: 20px 0;
  -webkit-text-align: center;
  text-align: center;
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  font-size: .8rem;
  letter-spacing: 2.5px;
  position: relative;
  background-color: var(--mtl-blue);
  text-decoration: none;
  color: white;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  overflow: hidden;
  border: none;
  &.full-width {
    width: calc(100% - 20px);
    margin: unset;
    display: block;
    padding: 10px;
  }
  &.paypal {
    height: 45px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
}

hr {
  width: 100%;
  height: 1px;
  border-width: 0;
  color: #ccc;
  background-color: #ccc;
}
.paypal-donation-button {
  height: 100%;
}

.map-overlay-wrapper {
  // background-color: var(--mtl-dark-background);
  background-color: #373A39e0;
  backdrop-filter: blur(8px);
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px 3px rgba(100,100,100, 0.1);
  width: fit-content;
  transition: 300ms;
  padding: 8px;
}

.button-icon {
  // height: @icon-size;
  transition: 0.5s;
  cursor: pointer;
  &:hover {
    transform: scale(1.2);
  }
  &.active {
    transform: scale(1.2);
  }
}

.map-marker-highlight {
  z-index: 20!important;
}

.map-marker {
  aspect-ratio: 1;
  background-color: rgba(255,255,255,.9);
  border: 2px solid;
  border-radius: 50%;
  min-width: 17px;
  min-height: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px 0px #000;
  transform: translateY(50%);
  font: 400 11px Roboto, Arial, sans-serif;
  transition: 300ms;
  cursor: pointer;
  &.highlight {
    // background-color: rgba(var(--mtl-blue-rgb), 1);
    // color: #fff !important;
    background: var(--mtl-highlight-blue);
    transform: translateY(50%) scale(2);
    box-shadow: 0 0 10px -3px #fff;
    
  }
  &.missing-coordinates {
    border-style: double;
  }
  &:hover {
    background-color: rgba(var(--mtl-blue-rgb), 1);
    color: #fff !important;
  }
  &.place {
    border-width: 4px;
  }
  &.close-up {
    min-width: 4px;
  }
  span {
    margin:auto;
  }
  &.not-current {
    opacity: 0.35;
  }
  &.residence {
    border-color: rgb(var(--mtl-aggregation-residence-rgb));
    color: rgb(var(--mtl-aggregation-residence-rgb));
  }
  &.emigration {
    border-color: rgb(var(--mtl-aggregation-emigration-rgb));
    color: rgb(var(--mtl-aggregation-emigration-rgb));
  }
  &.imprisonment {
    border-color: rgb(var(--mtl-aggregation-imprisonment-rgb));
    color: rgb(var(--mtl-aggregation-imprisonment-rgb));
  }
  &.expulsion {
    border-color: rgb(var(--mtl-aggregation-expulsion-rgb));
    color: rgb(var(--mtl-aggregation-expulsion-rgb));
  }
  &.deportation {
    border-color: rgb(var(--mtl-aggregation-deportation-rgb));
    color: rgb(var(--mtl-aggregation-deportation-rgb));
  }
  &.birth {
    border-color: rgb(var(--mtl-aggregation-birth-rgb));
    color: #000;
  }
  &.death {
    border-color: rgb(var(--mtl-aggregation-death-rgb));
    color: #000;
  }
}


.menu-bar-fixed-icons {
  display: flex;
  flex-direction: column;
  height: @menu-bar-width*2;
  width: @menu-bar-width;
  align-items: center;
  justify-content: space-around;
  .menu-bar-icon {
    display: block;
    padding: 4px 8px;
  }
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.2);
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input:checked + .slider {
    background-color: rgb(var(--mtl-blue-rgb)) !important;
  }
  &.active {
    background-color: rgb(var(--mtl-blue-rgb)) !important;
    .slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px rgb(var(--mtl-blue-rgb)) !important;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}



input {
  width: calc( 100% - 20px );
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  box-shadow: none;
  color: #001210;
  -webkit-writing-mode: horizontal-tb !important;
  font-style: ;
  font-variant-ligatures: ;
  font-variant-caps: ;
  font-variant-numeric: ;
  font-variant-east-asian: ;
  font-weight: ;
  font-stretch: ;
  font-size: ;
  font-family: ;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: flex;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  // margin: 5px 0;
  margin: 0;

  &::placeholder {
    font-size: 0.9rem;
    opacity: .3;
    letter-spacing: 3px;
  }

}

button, input[type="submit"], .button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: .9rem;
  // letter-spacing: 2.5px;
  border-radius: 0px!important;
  text-decoration: none;
  position: relative;
  background-color: #00A9B0;
  border: none;
  color: white;
  cursor: pointer;
  outline: none;
  transition: all 300ms;

  overflow: hidden;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:hover {
    box-shadow: 0px 0px 6px rgba(0,0,0,.5);
    background-color: #00A9B0;
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    33% {
      transform: scale(7);
      opacity: 1;
    }
    100% {
      transform: scale(20);
      opacity: 0;
    }
  }
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.4);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin: auto;
    display: none;
  }
  &.loading:before {
    display: unset;
    animation: pulse 2.4s infinite linear;
  }
  &.loading:after {
    display: unset;
    animation: pulse 4s 1.2s infinite linear;
  }
}

:ng-deep .map-container {
  width: 100% !important;
  height: 100% !important;
}

.admin-edit-button {
  border-radius: 5px !important;
  display: inline-flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: fit-content;
  // padding: 2px 10px;
  padding: 4px;
  aspect-ratio: 1;
  gap: 10px;
  margin: 0 10px;
  font-size: 14px;
  min-height: unset;
  height: 100%;
  max-height: 1rem;
  img {
    height: 60%;
    min-height: 10px;
  }
}

.bio-event {
  transition: 300ms;
  transform-origin: left;
  &.highlight {
    background: var(--mtl-highlight-blue);
    transform: scale(1.03);
  }
}


tbody {
  app-person-list-item:nth-of-type(even) {
    tr {
      background-color: rgba(0,0,0,0.1);
    }
  }
}

.osm-map-tiles {
  filter: sepia(0.5) grayscale(0.5) saturate(2);

}

.pz-zoom-control-position-bottom {
  left: unset!important;
  right: 10px!important;
}